[v-cloak] {
  display: none !important;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;

  &:hover {
    background: #555;
  }
}

.flex {
  display: flex;
  flex-wrap: wrap;
  min-height: calc(100vh - 40px);

  /*or use calc(100vh - header_height)*/
}

.timestamp {
  font-size: 12px;
  color: #8d8d8d;
}

.username {
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
}

#chatArea {
  overflow-x: hidden;
  overflow-y: hidden;
}

#chatWindow {}

#chatWindowText {
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  overflow-y: hidden;

  &:hover {
    overflow-y: scroll;
  }
}

.chatInput {
  width: 100%;
  display: inline-block;
  padding: 7px 5px 5px 2px;
  border: 1px solid #c5c5c5;
  border-radius: 3px;
}

.uploadButton {
  padding-right: 5px;
}

.reactIcons {
  padding-left: 5px;
}

.hiddenspacer {
  height: 15px;
}

@keyframes popupchat {
  from {
    bottom: -440px;
  }

  to {
    bottom: 0px;
  }
}

.stateOpen {
  box-shadow: 0px 0px 10px 0px #01010144;
  background: white;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  animation: popupchat 0.66s forwards ease-out !important;
}

#wsLiveChat {
  display: none;
}

#wsLiveChatInner {
  position: fixed;
  right: 25px;
  bottom: -30px;
  width: 400px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 1003;
}

#messageOptions {
  width: 200px;
}

#messageOptionsInner {
  float: right;
  height: 45px;
  background: #f2f2f2;
  padding: 5px;
  border: 1px solid #a6a6a6;
  border-radius: 5px;
}

#shadowChatInputTextarea {
  position: absolute;
  top: -1000px;
  min-height: 32px;
  font-size: 15px;
  border: none;
  width: 575px;
  float: left;
  padding: 5px;
  overflow-y: hidden;
  line-height: 18px;
}

#chatInputTextarea {
  font-size: 15px;
  overflow-y: hidden;
  line-height: 18px;
  border-bottom: 1px solid #bdbdbd;
}

.reactIcons {
  float: right;
}

.uploadButton {
  float: left;
}

.roomDetails {
  padding-left: 10px;
}

.activeRoom {
  background: #000;
}

.usersTyping {
  font-size: 12px;
  color: #7d7d7d;
}

.message {
  margin-top: 10px;
  min-height: 50px;
  clear: both;

  p {
    font-size: 20px;
    padding: 5px;
    margin-top: 0px;
    line-height: 20px;
    margin-left: 0px;
    margin-bottom: 10px;
  }
}

.system {
  min-height: 0px !important;
  text-align: center;
  width: 100%;
  background: #505050;
  color: white;
  height: 30px;
  border-radius: 5px;
  line-height: 30px;
  text-transform: capitalize;
}

.user {
  float: right;
}

.client {
  max-width: 75%;
  float: left;
}

.user-message {
  background: #cecece;
  border-radius: 5px;
  color: #4e4e4e;
}

.client-message {
  background: #81a7e0;
  border-radius: 5px;
  color: #ffffff;
}

.message p code {
  color: #3b3b3b;
  font-family: monospace, monospace;
  font-size: 10px;
  padding: 5px;
  background: #fafafa;
  width: 100%;
  border: 1px solid #cfcfcf;
}

.fileupload {
  opacity: .3;
  background: #fff;
}

.uploadedImageContainer {
  padding: 20px;
  border: 1px solid #dadada;
  background: #f7f7f7;
}

.snippet {
  font-family: Consolas, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
  font-size: 12px;
  overflow: auto;
  background: #f5f5f5;
  border: 1px solid #b5b5b5;
  margin-bottom: 0px;
  min-width: 75%;
  white-space: pre-wrap;
}

.snippetActions {
  text-align: center;
  background: #dcdcdc;
  display: block;
  padding: 3px;
  border: 1px solid #acacac;
  color: #858585;
}

.btn {
  border-radius: 999px;
}

.btn-start-lc {
  background: #4eb246;
  border: 0;
  color: #fff;
  text-transform: none;
  text-align: center;
  width: 100%;
}

.btn-start-lc:hover {
  color: #fff;
  background-color: #42973b;
}

.btn-primary:hover {
  color: #fff;
  background-color: #42973b;
}

.rate-link2 {
  /*background-color: #fff4b4;
  border: 1px solid #ccbd68;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  margin-left: 2px;
  margin-right: 2px;
  text-align: center;
  transition: .3s ease-in-out;
  width: 30px;
  &:hover {
    text-decoration: none;
  }*/
  display: inline-block;
  width: 35px;
  height: 35px;
  background: lightgray url('/assets/images/general/star.svg') 7px 7px/21px no-repeat;

  &--hover {
    background: #47a541 url('/assets/images/general/star.svg') 7px 7px/21px no-repeat;
  }
}

.leave-chat-feedback {
  background-color: #fff;
  //border: 1px solid #dededc;
  text-align: left;
  margin-left: auto;
  margin: 10px;
  padding: 10px;

  > {
    h2 {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      line-height: 27px;
      margin: 0;
      text-transform: none;
    }

    h3 {
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      margin-bottom: 30px;
      margin-top: 12px;
    }
  }
}

.email-chat-transcript {
  background-color: #fff;
  //-border: 1px solid #dededc;
  text-align: left;
  margin: 10px;
  padding: 10px;

  > {
    h2 {
      font-family: Montserrat, sans-serif;
      font-size: 18px;
      line-height: 27px;
      margin: 0;
      text-transform: none;
    }

    h3 {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      margin: 0;
      margin-bottom: 30px;
      margin-top: 12px;
    }
  }
}

#chatStatus {
  height: 25px;
  padding-left: 5px;
  color: white;
}

.loader {
  position: relative;
  top: 100px;
  border: 16px solid #f3f3f3;

  /* Light grey */
  border-top: 16px solid #3498db;

  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.close-chat {
  color: #d49090;
  font-weight: bold;
  font-size: 1.2rem;
}

#startchat-button {
  font-weight: 700;
  text-transform: none;
}

textarea#description {
  font-family: inherit;
  font-size: 14px;
}

.chat-text-agent {
  > {
    div {
      overflow-wrap: break-word;

      > {
        p {
          margin: 0;
        }
      }
    }
  }
}

.chat-text-client {
  > {
    div {
      overflow-wrap: break-word;

      > {
        p {
          margin: 0;
        }
      }
    }
  }
}

#center-button {
  transform-origin: 25% 50%;
  transition: transform .5s;
}

#a-center-button {
  position: absolute;
  top: 100px;
  left: 100px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: radial-gradient(#1e9a0f, #e6ffe8);
  border-radius: 19px;
  color: white;
  z-index: 1;
}

.a-menu-item {
  position: absolute;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: left .5s, top .5s, opacity .5s;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background: radial-gradient(#bdffb5, #a2d2a6);
  border-radius: 19px;
  color: black;
  z-index: -1;
}

.a-menu-item {
  &hover {
    z-index: 1;
    box-shadow: 3px 4px 12px 0px #4b8a50;
  }
}

@media(max-width: 575.98px) {
  #wsLiveChatInner {
    width: 330px;
  }
}
